import { gql } from "@apollo/client";
import { PaymentMethod, paymentMethodFragment } from "common/graphql";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($payeeAccountId: uuid!) {
    paymentMethods: financial_payment_method(
      where: { applicable_gateways: { payee_account_id: { _eq: $payeeAccountId } } }
      order_by: [{ is_default: desc }, { created_at: desc }]
    ) {
      ...paymentMethodFragment
    }
  }
  ${paymentMethodFragment}
`;

export interface GetPaymentMethodsVars {
  payeeAccountId: string;
}

export interface GetPaymentMethodsData {
  paymentMethods: PaymentMethod[];
}
