import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";
import { Theme } from "@mui/material/styles";

/**
 * Component that displays the current desired balance of a wallet
 * @param amount Current desired balance amount
 * @constructor
 */
export const DesiredBalanceDisplay: React.FC<DesiredBalanceDisplayProps> = ({ amount }) => {
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: 1.5,
        border: 1,
        borderColor: (theme: Theme) => theme.palette.grey["300"],
        width: "75px",
        height: "59px",
      }}
    >
      <Typography variant={"body1"}>For</Typography>
      <Typography variant={"h7"}>{`$${formatCurrency(amount)}`}</Typography>
    </Box>
  );
};

export interface DesiredBalanceDisplayProps {
  amount: number;
}

export default DesiredBalanceDisplay;
