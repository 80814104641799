import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Dispute } from "common/graphql";
import formatDate from "date-fns/format";
import Paper from "@mui/material/Paper";
import { formatCurrency } from "common/format/currency";
import { Theme } from "@mui/material/styles";

export const DisputeInformation: React.FC<DisputeInformationProps> = ({ dispute }) => {
  const disputeStatusVisible = dispute.status === "denied" || dispute.status === "accepted";
  return (
    <Paper>
      <Box
        sx={{
          pt: 2.5,
          px: 3,
          pb: 1.5,
          display: "flex",
          justifyContent: disputeStatusVisible ? "space-between" : "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant={"h5"} fontWeight={400}>
          Dispute
        </Typography>
        {disputeStatusVisible && (
          <Typography
            variant={"h7"}
            color={(theme: Theme) => (dispute.status === "denied" ? "#F2B84B" : theme.palette.success.dark)}
          >
            {dispute.status === "denied" ? "Denied" : "Approved"}
          </Typography>
        )}
      </Box>
      <Box
        py={1.5}
        px={3}
        color={(theme: Theme) => theme.palette.text.primary}
        bgcolor={(theme: Theme) => theme.palette.grey[100]}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle1" fontWeight={600}>
            Disputed Time
          </Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            Disputed Amount
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle1" fontWeight={400}>
            {formatDate(new Date(dispute.startDisputedTime), TIME_FORMAT)} -{" "}
            {formatDate(new Date(dispute.endDisputedTime), TIME_FORMAT)}
          </Typography>
          <Typography variant="subtitle1" fontWeight={400}>
            ${formatCurrency(dispute.amount)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const TIME_FORMAT = "h:mm aaa";

export interface DisputeInformationProps {
  dispute: Dispute;
}
