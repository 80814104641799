import { gql } from "@apollo/client";

export const enforcementIntentSummaryFragment = gql`
  fragment enforcementIntentSummaryFragment on enforcement_intent {
    id
    code
    noticeNumber: notice_number
    noticeType: type
    issuedAt: issued_at
    totalAmountDue: total_amount_due
    licensePlateNumber: license_plate_number
    licensePlateRegion: license_plate_region
    violationType: violation_type
  }
`;

export interface EnforcementIntentSummary {
  id: string;
  code: string;
  noticeNumber: string;
  noticeType: EnforcementIntentType;
  issuedAt: string;
  totalAmountDue: number;
  licensePlateNumber: string;
  licensePlateRegion: string;
  violationType: string; // TODO: enum
}

export type EnforcementIntentType = "citation" | "invoice";
