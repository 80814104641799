import { gql } from "@apollo/client";
import { enforcementIntentSummaryFragment, EnforcementIntentSummary } from "../fragments/enforcementIntentSummary";

export const GET_VOIDED_ENFORCEMENT_INTENTS = gql`
  query GetVoidedEnforcementIntents($offset: Int = 0) {
    enforcementIntents: enforcement_intent(
      where: { voided_at: { _is_null: false }, voided_reason: { _neq: internal_review } }
      offset: $offset
      limit: 100 #TODO: add order by
    ) {
      ...enforcementIntentSummaryFragment
    }
  }
  ${enforcementIntentSummaryFragment}
`;

export interface GetVoidedEnforcementIntentsVars {
  offset: number;
}

export interface GetVoidedEnforcementIntentsData {
  enforcementIntents: EnforcementIntentSummary[];
}

export const GET_VOIDED_ENFORCEMENT_INTENTS_OVERVIEW = gql`
  query GetVoidedEnforcementIntentsOverview(
    $daysAgo365: timestamptz!
    $daysAgo30: timestamptz!
    $daysAgo7: timestamptz!
  ) {
    past365Days: enforcement_intent_aggregate(
      where: { voided_at: { _is_null: false, _gte: $daysAgo365 }, voided_reason: { _neq: internal_review } }
    ) {
      aggregate {
        count
      }
    }
    past30Days: enforcement_intent_aggregate(
      where: { voided_at: { _is_null: false, _gte: $daysAgo30 }, voided_reason: { _neq: internal_review } }
    ) {
      aggregate {
        count
      }
    }
    past7Days: enforcement_intent_aggregate(
      where: { voided_at: { _is_null: false, _gte: $daysAgo7 }, voided_reason: { _neq: internal_review } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetVoidedEnforcementIntentsOverviewVars {
  daysAgo365: string;
  daysAgo30: string;
  daysAgo7: string;
}

export interface GetVoidedEnforcementIntentsOverviewData {
  past365Days: {
    aggregate: {
      count: number;
    };
  };
  past30Days: {
    aggregate: {
      count: number;
    };
  };
  past7Days: {
    aggregate: {
      count: number;
    };
  };
}
