import React from "react";
import PartnerLogoImage from "../PartnerLogoImage";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { CityOption } from "common/constants";

export const PartnerLandingPageDesktopPartnerBranding: React.FC<PartnerLandingPageDesktopPartnerBrandingProps> = ({
  cityOption,
}) => {
  let text = "";
  const theme = useTheme();

  switch (cityOption.value) {
    case "fortlee":
    case "pittsburgh":
    case "bethlehem":
    case "philly":
      text = `${cityOption.landingPageLabel} Smart Loading Zones`;
      break;
    default:
      break;
  }

  return (
    <>
      <PartnerLogoImage cityOption={cityOption} />
      <Typography variant="h7" sx={{ ml: 1.5, color: theme.palette.primary.black }}>
        {text}
      </Typography>
    </>
  );
};

export interface PartnerLandingPageDesktopPartnerBrandingProps {
  cityOption: CityOption;
}

export default PartnerLandingPageDesktopPartnerBranding;
