import React, { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@apollo/client";
import SavedPaymentMethod from "@app.automotus.io/components/SavedPaymentMethod/SavedPaymentMethod";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { GetPaymentMethodsVars, PaymentMethod } from "common/graphql";
import { CustomerPaymentProfile } from "api/adaptors/AuthorizeNet";
import { AccountSection } from "@app.automotus.io/components/scenes/Account/AccountSection";
import Typography from "@mui/material/Typography";
import PaymentSectionDialog from "@app.automotus.io/components/scenes/Account/PaymentSection/PaymentSectionDialog/PaymentSectionDialog";
import Stack from "@mui/material/Stack";
import WalletRefundRequestLink from "@app.automotus.io/components/LinkButton/WalletRefundRequestLink";
import { GET_PAYMENT_METHODS, GetPaymentMethodsData } from "common/graphql";
import { LoadingButton } from "@mui/lab";
import { useActivePayee, usePaymentsHelpers } from "@app.automotus.io/components/hooks";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import ALERTS from "./alerts";
import Box from "@mui/material/Box";

export const PaymentSection: React.FC<PaymentInformationProps> = ({
  refundRequestDialogOpen = false,
  setRefundRequestDialogOpen,
}: PaymentInformationProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<
    "add" | "remove" | "update" | "request_refund" | "refund_request_submitted" | null
  >(null);
  const [dialogPaymentMethod, setDialogPaymentMethod] = useState<PaymentMethod | null>(null);
  const { activePayee, loading: activePayeeLoading } = useActivePayee();
  const {
    data: paymentMethodsData,
    error: paymentMethodsError,
    refetch: refetchPaymentMethod,
    loading: paymentMethodsLoading,
  } = useQuery<GetPaymentMethodsData, GetPaymentMethodsVars>(GET_PAYMENT_METHODS, {
    skip: !activePayee,
    variables: {
      payeeAccountId: activePayee?.payeeAccountId || "",
    },
  });

  const loading = activePayeeLoading || paymentMethodsLoading;

  const [{ updateDefaultPaymentMethod }] = usePaymentsHelpers();

  const { publishSnackbarMessage } = useSnackPackContext();

  let paymentMethods: PaymentMethod[] = [];

  if (paymentMethodsError) {
    console.error("failed to fetch payment methods", paymentMethodsError);
    refetchPaymentMethod();
  }
  if (paymentMethodsData) {
    paymentMethods = paymentMethodsData.paymentMethods;
  }

  const handleDialogClose = () => {
    if (setRefundRequestDialogOpen) setRefundRequestDialogOpen(false);
    setDialogOpen(false);
  };

  const handleDialogExited = () => {
    setDialogMode(null);
    setDialogPaymentMethod(null);
  };

  const handleClickAdd = () => {
    setDialogOpen(true);
    setDialogMode("add");
  };

  const handleClickRemove = (e: React.MouseEvent<HTMLButtonElement>, paymentMethod: PaymentMethod) => {
    setDialogOpen(true);
    setDialogMode("remove");
    setDialogPaymentMethod(paymentMethod);
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>, paymentMethod: PaymentMethod) => {
    setDialogPaymentMethod(paymentMethod);
    setDialogOpen(true);
    setDialogMode("update");
  };

  const handleClickRequestWalletRefund = () => {
    setDialogOpen(true);
    setDialogMode("request_refund");
  };

  const handleClickSetDefault = async (paymentMethodId: string) => {
    try {
      await updateDefaultPaymentMethod({
        paymentMethodId,
      });

      publishSnackbarMessage(ALERTS.success["setDefault"]);
    } catch (err) {
      console.error(err);
      publishSnackbarMessage(ALERTS.failure["setDefault"]);
    }
  };

  return (
    <AccountSection title={"Payment Information"}>
      <Box>
        <LoadingSkeleton loading={loading} width="100%">
          <Stack spacing={2}>
            {paymentMethods &&
              paymentMethods.map((paymentMethod, index) => (
                <SavedPaymentMethod
                  key={index}
                  loading={loading}
                  paymentMethod={paymentMethod}
                  onClickRemove={handleClickRemove}
                  onClickEdit={handleClickEdit}
                  onClickSetDefault={() => handleClickSetDefault(paymentMethod.id)}
                  enableRemove={paymentMethods.length > 1}
                  isDefault={paymentMethod.isDefault}
                />
              ))}
          </Stack>
        </LoadingSkeleton>
      </Box>
      <Box sx={{ mt: 4 }}>
        <LoadingSkeleton loading={loading} width="100%">
          <Box>
            <Typography variant="body1">Other:</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
              No other payment methods have been added yet
            </Typography>
          </Box>
        </LoadingSkeleton>
      </Box>
      <Box sx={{ mt: 4 }}>
        <LoadingButton variant="outlined" fullWidth loading={loading} onClick={handleClickAdd}>
          + Add a Payment Method
        </LoadingButton>
      </Box>
      <Box sx={{ mt: { xs: 3.5, lg: 5.5 } }} display={"flex"} justifyContent={"center"}>
        <WalletRefundRequestLink loading={loading} onClick={handleClickRequestWalletRefund} />
      </Box>
      <PaymentSectionDialog
        mode={refundRequestDialogOpen ? "request_refund" : dialogMode}
        open={refundRequestDialogOpen || dialogOpen}
        onBackdropClick={handleDialogClose}
        onClose={handleDialogClose}
        onExited={handleDialogExited}
        paymentMethod={dialogPaymentMethod ? paymentMethodToCustomerPaymentProfile(dialogPaymentMethod) : undefined}
        redirected={refundRequestDialogOpen}
      />
    </AccountSection>
  );
};

const paymentMethodToCustomerPaymentProfile = (paymentMethod: PaymentMethod): CustomerPaymentProfile => {
  return {
    customerProfileId: "",
    customerPaymentProfileId: paymentMethod.id,
    // TODO: get default prop
    defaultPaymentProfile: false,
    payment: {
      creditCard: {
        cardLast4: paymentMethod.maskedNumber,
        expMonth: "",
        expYear: "",
        cardType: paymentMethod.paymentMethodIssuer,
        isPaymentToken: paymentMethod.type === "wallet",
      },
    },
  };
};

export interface PaymentInformationProps {
  refundRequestDialogOpen?: boolean;
  setRefundRequestDialogOpen?: Dispatch<SetStateAction<boolean>>;
}

export default PaymentSection;
