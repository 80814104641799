import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import LocalLevelError from "../common/LocalLevelError";
import { formatCurrency } from "common/format";
import SupportIconBlue from "../icons/SupportIconBlue";
import BottomSheet from "../BottomSheet";
import PartnerLandingPageHowItWorks from "../scenes/PartnerLandingPage/PartnerLandingPageHowItWorks";
import { Theme } from "@mui/material/styles";

/**
 * The invoice page is a critical page for standalone bill-by-mail payments.
 * It should display invoice details, total amount owed, a brief bill as well as a cost breakdown of each park event.
 * The page should be well-established and user-friendly, and therefore it must follow design specifications strictly.
 */
export const BillByMailInvoiceActivation: React.FC<BillByMailInvoiceActivationProps> = ({
  invoiceId,
  onRegisterAndPay = () => undefined,
  onPayAsGuest = () => undefined,
}) => {
  const {
    data: invoiceData,
    refetch: refetchInvoice,
    loading: invoiceLoading,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const theme = useTheme();
  const isMobile = useIsMobile();
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const navigate = useNavigate();
  const [howItWorksSheetOpen, setHowItWorksSheetOpen] = useState(false);

  const handleOpenHowItWorksSheet = () => {
    setHowItWorksSheetOpen(true);
  };

  const handleCloseHowItWorksSheet = () => {
    setHowItWorksSheetOpen(false);
  };

  if ((invoiceId && invoiceError) || (!invoiceData?.invoice && !invoiceLoading)) {
    return <LocalLevelError onTryAgain={refetchInvoice} />;
  }

  const invoice = invoiceData?.invoice;

  return (
    <Box sx={{ width: { xs: "100%" } }}>
      <LoadingSkeleton loading={invoiceLoading}>
        <Box sx={{ mt: { xs: 0, md: 2 }, p: 1.5, backgroundColor: "rgba(13, 85, 191, 0.08)" }}>
          <Typography variant="h7" fontWeight={500}>
            Save 75% with CurbPass
          </Typography>
          <Typography variant="body1">
            A Curbpass account gives you access to every Smart Loading Zone and saves you up to 75% in parking fees.
          </Typography>
          {isMobile && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                boxSizing: "border-box",
                mt: 1,
                px: 1,
                py: 0.75,
              }}
            >
              <Link
                variant="body1"
                sx={{ fontWeight: 600 }}
                underline={"hover"}
                component={"button"}
                onClick={() => {
                  handleOpenHowItWorksSheet();
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <SupportIconBlue />
                  <span style={{ marginLeft: "11px" }}>Learn more</span>
                </Box>
              </Link>
            </Box>
          )}
        </Box>
      </LoadingSkeleton>
      <LoadingSkeleton loading={invoiceLoading}>
        <Typography
          fontSize={{ xs: "1.5rem", tiny: "1.75rem", md: "2.125rem" }}
          fontWeight={500}
          mt={{ xs: 3, tiny: 4, md: 3 }}
        >
          Payment Preference
        </Typography>
      </LoadingSkeleton>
      <LoadingSkeleton loading={invoiceLoading}>
        <Box display={"flex"} flexDirection={"column"} mt={3}>
          <Typography fontSize={{ xs: "1rem", tiny: "1.25rem", md: "1.25rem" }} fontWeight={500}>
            You pay ${formatCurrency(invoice?.transaction_amount_due || 0)}
          </Typography>
          <Typography variant="caption" color={(theme: Theme) => theme.palette.text.secondary}>
            administrative fee will be waived
          </Typography>
        </Box>
      </LoadingSkeleton>
      <Box sx={{ mt: 2 }}>
        <LoadingSkeleton loading={invoiceLoading} width="100%">
          <Button
            variant="contained"
            fullWidth
            onClick={onRegisterAndPay}
            sx={{
              textTransform: "none",
              fontSize: {
                xs: "0.875rem",
                tiny: "1rem",
                md: "1.125rem",
              },
            }}
          >
            Register and Pay
          </Button>
        </LoadingSkeleton>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <LoadingSkeleton loading={invoiceLoading} width="100%">
          <Typography>
            Have an account?{" "}
            <Link sx={{ textDecoration: "none", fontWeight: { xs: 700, tiny: 400 } }} href={`/login`}>
              Login to save on fees
            </Link>
            .
          </Typography>
        </LoadingSkeleton>
      </Box>
      <Divider sx={{ color: "#757575", my: 2 }}>or</Divider>
      <LoadingSkeleton loading={invoiceLoading}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography fontSize={{ xs: "1rem", tiny: "1.25rem", md: "1.25rem" }} fontWeight={500}>
            You pay ${formatCurrency(invoice?.total_amount_due || 0)}
          </Typography>
          <Typography variant="caption" color={(theme: Theme) => theme.palette.text.secondary}>
            administrative fee will be applied in full
          </Typography>
        </Box>
      </LoadingSkeleton>
      <Box sx={{ mt: 2 }}>
        <LoadingSkeleton loading={invoiceLoading} width="100%">
          <Button
            variant="outlined"
            fullWidth
            onClick={onPayAsGuest}
            sx={{
              textTransform: "none",
              fontSize: {
                xs: "0.875rem",
                tiny: "1rem",
                md: "1.125rem",
              },
            }}
          >
            Pay as Guest
          </Button>
        </LoadingSkeleton>
      </Box>
      {isMobile && (
        <Box sx={{ mt: { xs: 2, tiny: 3, md: 2 } }}>
          <LoadingSkeleton loading={invoiceLoading} width="100%">
            <Button
              variant="text"
              fullWidth
              sx={{
                textTransform: "none",
                fontSize: {
                  xs: "0.875rem",
                  tiny: "1rem",
                  md: "1.125rem",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </LoadingSkeleton>
        </Box>
      )}
      <Box sx={{ mt: { xs: 1.5, tiny: 3, md: 9 } }}>
        <LoadingSkeleton loading={invoiceLoading} width="100%">
          <Button
            variant="text"
            fullWidth
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: 400,
            }}
            onClick={() => navigate("/support")}
          >
            Need Help?
          </Button>
        </LoadingSkeleton>
      </Box>
      {isMobile && (
        <BottomSheet
          marginTopSmall={45}
          marginTopMedium={49}
          open={howItWorksSheetOpen}
          onClose={handleCloseHowItWorksSheet}
        >
          <PartnerLandingPageHowItWorks />
          <Box sx={{ flex: 1 }} />
          <Button
            variant={"contained"}
            fullWidth
            onClick={handleCloseHowItWorksSheet}
            sx={{
              m: 2,
              height: isSmall ? "30px" : "36px",
              fontSize: isSmall ? "14px" : "16px",
              fontWeight: 600,
              textTransform: "none",
              width: `auto`,
            }}
          >
            Back
          </Button>
        </BottomSheet>
      )}
    </Box>
  );
};

export interface BillByMailInvoiceActivationProps {
  /** ID of the invoice */
  invoiceId: string;
  onRegisterAndPay?: () => void;
  onPayAsGuest?: () => void;
}

export default BillByMailInvoiceActivation;
