import { gql } from "@apollo/client";
import { enforcementIntentSummaryFragment, EnforcementIntentSummary } from "../fragments/enforcementIntentSummary";
import { OrderBy } from "../queryString";

export const GET_ENFORCEMENT_INTENTS_SUMMARY = gql`
  query GetEnforcementIntentsSummary(
    $offset: Int = 0
    $orderBy: [enforcement_intent_order_by!]
    $filter: enforcement_intent_bool_exp
  ) {
    enforcementIntents: enforcement_intent(where: $filter, offset: $offset, limit: 100, order_by: $orderBy) {
      ...enforcementIntentSummaryFragment
    }
    enforcementIntentAggregate: enforcement_intent_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
  ${enforcementIntentSummaryFragment}
`;

export interface GetEnforcementIntentsWhereFilter {
  license_plate?: { _eq: string };
  reviews?: {
    type: { _eq: "officer" };
    reviewed_at: { _is_null: true };
  };
  status?: { _eq: "requires_officer_review" };
  voided_at?: { _is_null: false };
  voided_reason?: { _neq: "internal_review" };
  ro_lookup_submitted_at?: { _is_null: false };
}

export interface GetEnforcementIntentsSummaryVars {
  offset: number;
  orderBy?: EnforcementIntentsOrderBy[];
  filter: GetEnforcementIntentsWhereFilter;
}

export interface GetEnforcementIntentsSummaryData {
  enforcementIntents: EnforcementIntentSummary[];
  enforcementIntentAggregate: {
    aggregate: {
      count: number;
    };
  };
}

export interface GetPendingEnforcementIntentsVars {
  offset: number;
  orderBy?: EnforcementIntentsOrderBy[];
}

export type EnforcementIntentsOrderBy = EnforcementIntentsOrderByIssuedAt;

export interface EnforcementIntentsOrderByIssuedAt {
  issued_at: OrderBy;
}

export interface GetPendingEnforcementIntentsData {
  enforcementIntents: EnforcementIntentSummary[];
  enforcementIntentAggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_PENDING_ENFORCEMENT_INTENTS_OVERVIEW = gql`
  query GetPendingEnforcementIntentsOverview {
    enforcementIntentAggregate: enforcement_intent_aggregate(
      where: {
        status: { _eq: requires_officer_review }
        reviews: { type: { _eq: officer }, reviewed_at: { _is_null: true } }
      }
    ) {
      aggregate {
        count
        sum {
          totalAmountDue: total_amount_due
        }
      }
    }
  }
`;

export interface GetPendingEnforcementIntentsOverviewData {
  enforcementIntentAggregate: {
    aggregate: {
      count: number;
      sum: {
        totalAmountDue: number;
      };
    };
  };
}
