import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CityOption } from "common/constants";
import React from "react";

export const PartnerLogoImage: React.FC<PartnerLogoImageProps> = ({ cityOption: { value, logoImageUrl } }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return <img src={logoImageUrl} style={{ height: isMobile ? 20 : 44 }} alt={`${value} Logo`} />;
};

export interface PartnerLogoImageProps {
  cityOption: CityOption;
}

export default PartnerLogoImage;
