import React from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useIsMobile } from "@app.automotus.io/components/hooks/useIsMobile";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { TransactionHeader } from "@app.automotus.io/components/TransactionHeader";
import Stack from "@mui/material/Stack";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import type { Theme } from "@mui/material/styles";

export const TransactionLayout: React.FC = () => {
  const { transactionId = "" } = useParams<{ transactionId: string }>();
  const matchDispute = useMatch("/transactions/:transactionId/dispute");

  return (
    <Container maxWidth={"lg"}>
      <BackButton sx={{ mb: { xs: 3, lg: 6 }, mt: { xs: 3, lg: 5 } }} title={matchDispute ? "Summary" : ""} />
      <Box display="flex" justifyContent="center" sx={{ pb: { xs: 6.25, lg: 17.25 } }}>
        <Box sx={{ maxWidth: 720 }}>
          <Stack spacing={3}>
            <TransactionHeader transactionId={transactionId} />
            <Outlet />
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

const BackButton: React.FC<ButtonBaseProps> = ({ sx, title, ...props }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <ButtonBase
      {...props}
      disableRipple={true}
      onClick={() => (title === "Summary" ? navigate(".", { replace: true }) : navigate(-1))}
      sx={{ ...sx, "& :hover": { color: (theme: Theme) => theme.palette.action.active } }}
    >
      <Box display={"flex"} alignItems={"center"} sx={{ p: 1 }}>
        <ArrowBackIos />
        {!isMobile && (
          <Typography variant={"h7"} sx={{ ml: 0.5 }}>
            Back {title && `to ${title}`}
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
};
