import React, { useEffect, useMemo, useState } from "react";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import DashboardContentLayout from "@app.automotus.io/components/Layout/DashboardContentLayout";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  GET_INVOICES_FOR_VEHICLE,
  GET_PAYMENT_METHODS,
  GET_REGISTERED_VEHICLE,
  GetInvoicesByVehicleData,
  GetInvoicesByVehicleVars,
  GetPaymentMethodsData,
  GetPaymentMethodsVars,
  GetRegisteredVehicleData,
  GetRegisteredVehicleVars,
  PAY_INVOICE,
  PayInvoiceData,
  PayInvoiceVars,
} from "common/graphql";
import { RegisteredVehicle, VehicleAccountAttachmentType } from "common/graphql/fragments/registeredVehicleFragment";
import { VehicleInvoiceCardView } from "@app.automotus.io/components/VehicleInvoice/VehicleInvoiceCard";
import { SNACKBAR_MSGS, useSnackPackContext } from "../context/SnackPack";
import VehicleStatus from "./VehicleStatus";
import VehicleInfo from "./VehicleInfo";
import InvoiceTable from "./InvoiceTable";
import ActionButton from "./ActionButton";
import VehicleInvoiceCardStatus from "./VehicleInvoiceCardStatus";
import { getPaymentMethodDisplay } from "./util";
import { VehicleInvoiceTotalFooter } from "./VehicleInvoiceTotalFooter";
import LoadingButton from "@mui/lab/LoadingButton";

export const VehicleInvoice: React.FC = () => {
  const { loading: profileLoading, refetch, error: profileError } = useUserProfile();
  const { vehicleId = "" } = useParams<{ vehicleId: string }>();
  const navigate = useNavigate();
  const [payInvoice] = useMutation<PayInvoiceData, PayInvoiceVars>(PAY_INVOICE);
  const { publishSnackbarMessage } = useSnackPackContext();
  const isMobile = useIsMobile();
  const {
    data: registeredVehicleData,
    refetch: refetchRegisteredVehicle,
    loading: registeredVehicleLoading,
  } = useQuery<GetRegisteredVehicleData, GetRegisteredVehicleVars>(GET_REGISTERED_VEHICLE, {
    variables: {
      id: vehicleId,
    },
  });
  const [isPayingInvoices, setIsPayingInvoices] = useState(false);

  const registerVehicleInfo: RegisteredVehicle = {
    id: registeredVehicleData?.registeredVehicle.id || vehicleId,
    licensePlate: registeredVehicleData?.registeredVehicle.licensePlate || "",
    stateCode: registeredVehicleData?.registeredVehicle.stateCode || "",
    type: registeredVehicleData?.registeredVehicle.type || null,
    make: registeredVehicleData?.registeredVehicle.make || null,
    model: registeredVehicleData?.registeredVehicle.model || null,
    year: registeredVehicleData?.registeredVehicle.year || null,
    createdAt: registeredVehicleData?.registeredVehicle.createdAt || new Date(),
    accounts: registeredVehicleData?.registeredVehicle.accounts || [],
  };

  const isRegistered =
    registerVehicleInfo.accounts.length !== 0 &&
    (registerVehicleInfo.accounts[0].attachment_type === VehicleAccountAttachmentType.PAID_REGISTRATION ||
      registerVehicleInfo.accounts[0].attachment_type === VehicleAccountAttachmentType.UNPAID_REGISTRATION);

  const {
    data: invoicesForVehicleData,
    refetch: refetchInvoicesForVehicle,
    loading: invoicesForVehicleLoading,
  } = useQuery<GetInvoicesByVehicleData, GetInvoicesByVehicleVars>(GET_INVOICES_FOR_VEHICLE, {
    variables: {
      registeredVehicleId: vehicleId,
    },
  });

  const invoices = invoicesForVehicleData?.invoices || [];

  // TODO: here to indicate that this query will not work for users who have wallets with multiple payees.
  const {
    data: paymentMethodsData,
    refetch: refetchPaymentMethods,
    loading: paymentMethodsLoading,
  } = useQuery<GetPaymentMethodsData, GetPaymentMethodsVars>(GET_PAYMENT_METHODS, {
    skip: !invoices?.length,
    variables: {
      payeeAccountId: invoices[0]?.payee_account_id || "",
    },
  });

  const paymentMethods = useMemo(() => {
    if (paymentMethodsData) {
      return paymentMethodsData.paymentMethods;
    }

    return [];
  }, [paymentMethodsData]);

  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      setPaymentMethodId(null);
    } else {
      setPaymentMethodId(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  const outstandingInvoices = invoices.filter((invoice) => invoice.amount_paid === 0);

  const payableInvoicesCount = outstandingInvoices.length;

  const pastInvoices = invoices.filter((invoice) => invoice.amount_paid > 0);

  const handlePayAllInvoices = async () => {
    if (!paymentMethodId) return;

    setIsPayingInvoices(true);

    try {
      await Promise.all(
        outstandingInvoices.map(async (invoice) => {
          await payInvoice({
            variables: {
              invoiceId: invoice.id,
              paymentMethodId,
            },
          });
        }),
      );

      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_SUCCESS);
    } catch (err) {
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
      console.error("failed to pay invoice", err);
    }
    setIsPayingInvoices(false);
  };

  const [isPastInvoicesShown, setIsPastInvoicesShown] = useState(false);

  const noOutstandingInvoices = outstandingInvoices.length === 0;
  let totalAmountDue = 0;
  if (invoices && invoices.length > 0) {
    totalAmountDue = outstandingInvoices.reduce((acc, cur) => acc + cur.total_amount_due, 0);
  }

  const loading = profileLoading || registeredVehicleLoading || paymentMethodsLoading || invoicesForVehicleLoading;

  if (profileError) {
    return <LocalLevelError onTryAgain={refetch} />;
  }

  if (!registeredVehicleData && !registeredVehicleLoading) {
    return <LocalLevelError onTryAgain={refetchRegisteredVehicle} />;
  }

  if (!paymentMethodsData && !paymentMethodsLoading) {
    return <LocalLevelError onTryAgain={refetchPaymentMethods} />;
  }

  if (!invoicesForVehicleData && !invoicesForVehicleLoading) {
    return <LocalLevelError onTryAgain={refetchInvoicesForVehicle} />;
  }

  return (
    <>
      <DashboardContentLayout sx={{ my: { xs: 1.5, lg: 3 } }}>
        <Box sx={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <LoadingSkeleton loading={loading}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <IconButton
                  aria-label={"back-button"}
                  sx={{ color: "black" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>
                <Typography sx={{ fontSize: { xs: "24px", tiny: "28px", lg: "34px" }, fontWeight: 500 }}>
                  {registerVehicleInfo.licensePlate}
                </Typography>
              </Box>
            </LoadingSkeleton>
            {isMobile && (
              <LoadingSkeleton loading={loading}>
                <VehicleStatus isRegistered={isRegistered} />
              </LoadingSkeleton>
            )}
          </Box>
          {!noOutstandingInvoices && !isRegistered && (
            <LoadingSkeleton loading={loading}>
              <VehicleInvoiceCardStatus
                status="critical"
                message="This vehicle cannot be registered until all associated invoices are paid in full."
              />
            </LoadingSkeleton>
          )}
          {!noOutstandingInvoices && isRegistered && (
            <LoadingSkeleton loading={loading}>
              <VehicleInvoiceCardStatus
                status="critical"
                message="Our records show that you have one or more outstanding invoices. Please pay now."
              />
            </LoadingSkeleton>
          )}
          {!isMobile ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img
                  src={`${process.env.REACT_APP_ASSET_ENDPOINT}/icons/car.png`}
                  style={{ height: "96px", width: "96px", marginRight: "24px" }}
                  alt="vehicle"
                />
                <LoadingSkeleton loading={loading}>
                  <VehicleInfo registerVehicleInfo={registerVehicleInfo} />
                </LoadingSkeleton>
              </Box>
              {!isMobile && (
                <LoadingSkeleton loading={loading}>
                  <VehicleStatus isRegistered={isRegistered} />
                </LoadingSkeleton>
              )}
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
                <img
                  src={`${process.env.REACT_APP_ASSET_ENDPOINT}/icons/car.png`}
                  style={{ height: "96px", width: "96px", marginRight: "24px" }}
                  alt="vehicle"
                />
                <LoadingSkeleton loading={loading}>
                  <Box sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
                    <VehicleInfo registerVehicleInfo={registerVehicleInfo} />
                  </Box>
                </LoadingSkeleton>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              mt: { sm: 1 },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ color: noOutstandingInvoices ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.87)" }}>
              <LoadingSkeleton loading={loading}>
                <Typography variant="h7" fontWeight={500}>
                  Outstanding Invoice(s)
                </Typography>
              </LoadingSkeleton>
              <LoadingSkeleton loading={loading}>
                <Typography variant="body1">
                  Your primary payment method on file will be charged to pay your invoice(s).
                </Typography>
              </LoadingSkeleton>
            </Box>
            {!isMobile && (
              <LoadingSkeleton loading={loading}>
                <Select
                  sx={{
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    height: { xs: "30px", tiny: "47px" },
                  }}
                  variant="outlined"
                  value={paymentMethodId}
                  autoWidth
                  disabled={noOutstandingInvoices}
                  onChange={(e) => {
                    setPaymentMethodId(e.target.value);
                  }}
                >
                  {paymentMethods.length !== 0 &&
                    paymentMethods.map((paymentMethod) => (
                      <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                        Pay with {getPaymentMethodDisplay(paymentMethod)}
                      </MenuItem>
                    ))}
                </Select>
              </LoadingSkeleton>
            )}
          </Box>
          <LoadingSkeleton loading={loading}>
            {noOutstandingInvoices && (
              <Typography variant="body1" color="rgba(0, 0, 0, 0.38)">
                No Outstanding Invoices
              </Typography>
            )}
            {!noOutstandingInvoices &&
              (!isMobile ? (
                <InvoiceTable
                  isRegistered={isRegistered}
                  invoices={outstandingInvoices}
                  paymentMethodId={paymentMethodId}
                />
              ) : (
                <VehicleInvoiceCardView isRegistered={isRegistered} invoices={outstandingInvoices} />
              ))}
          </LoadingSkeleton>
          {!isMobile && (
            <Box>
              {!noOutstandingInvoices && (
                <LoadingSkeleton loading={loading}>
                  <LoadingButton
                    loading={isPayingInvoices}
                    variant="contained"
                    disabled={noOutstandingInvoices || payableInvoicesCount !== outstandingInvoices.length}
                    onClick={handlePayAllInvoices}
                    fullWidth
                    sx={{
                      textTransform: "unset",
                      py: { xs: 0.5, tiny: 0.75, lg: 1 },
                      fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                      fontWeight: 500,
                    }}
                  >
                    Pay all Invoices
                  </LoadingButton>
                </LoadingSkeleton>
              )}
              {!isPastInvoicesShown && (
                <LoadingSkeleton loading={loading}>
                  <Button
                    variant="text"
                    onClick={() => {
                      setIsPastInvoicesShown(true);
                    }}
                    disabled={!isRegistered || pastInvoices.length === 0}
                    fullWidth
                    sx={{
                      textTransform: "unset",
                      mt: 2,
                      fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                      height: { xs: "30px", tiny: "36px", lg: "42px" },
                      fontWeight: 500,
                    }}
                  >
                    View Past Invoices
                  </Button>
                </LoadingSkeleton>
              )}
              {isPastInvoicesShown &&
                (!isMobile ? (
                  <InvoiceTable isRegistered={isRegistered} invoices={pastInvoices} />
                ) : (
                  <VehicleInvoiceCardView isRegistered={isRegistered} invoices={pastInvoices} />
                ))}
              <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.12)", my: 4 }} />
              {/* <Box
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "normal",
                }}
              >
                <LoadingSkeleton loading={loading}>
                  <Typography variant="h7">Missing Information</Typography>
                </LoadingSkeleton>
                <LoadingSkeleton loading={loading}>
                  <Typography variant="body1">We need a few more details to add your vehicle.</Typography>
                </LoadingSkeleton>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 3, pb: 3, justifyContent: "space-between" }}>
                <Box>
                  <Select
                    sx={{
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      height: { xs: "30px", tiny: "47px" },
                      width: { lg: "486px", tiny: "auto" },
                    }}
                    variant="outlined"
                    value={1}
                    autoWidth
                  >
                    <MenuItem value={1}>Vehicle Type</MenuItem>
                  </Select>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log("On Save Missing Info");
                  }}
                  fullWidth
                  sx={{
                    textTransform: "unset",
                    py: { xs: 0.5, tiny: 0.75, lg: 1 },
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 500,
                    width: "242px",
                  }}
                >
                  Save
                </Button>
              </Box> */}
            </Box>
          )}
        </Box>
      </DashboardContentLayout>

      {isMobile && (
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            flex: "1",
            maxWidth: "100vw",
            width: "100%",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "24px 0px",
          }}
        >
          <Box
            sx={{
              height: 1,
              alignSelf: "stretch",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              padding: "8px 16px",
            }}
          >
            <VehicleInvoiceTotalFooter
              invoices={outstandingInvoices}
              disabled={noOutstandingInvoices || payableInvoicesCount !== outstandingInvoices.length}
              totalDueAmount={totalAmountDue}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0 16px",
            }}
          >
            <ActionButton
              disabled={!isRegistered || pastInvoices.length === 0}
              variant="outlined"
              sx={{ fontSize: { xs: "14px", tiny: "16px" } }}
            >
              View Past Invoices
            </ActionButton>
          </Box>
          <Box sx={{ mt: { xs: "8px", lg: "0px" }, pb: { xs: "10px" }, width: "100%" }}>
            <ActionButton
              onClick={() => navigate(-1)}
              variant="text"
              sx={{ fontSize: { xs: "14px", tiny: "16px" }, fontWeight: isMobile ? 500 : 600 }}
            >
              Back
            </ActionButton>
          </Box>
        </Paper>
      )}
    </>
  );
};
