import { gql } from "@apollo/client";
import { TransactionDetail, transactionDetailFragment } from "./transactionDetailFragment";
import { EnforcementIntentSummary, enforcementIntentSummaryFragment } from "./enforcementIntentSummary";

export const enforcementIntentDetailFragment = gql`
  fragment enforcementIntentDetailFragment on enforcement_intent {
    ...enforcementIntentSummaryFragment
    clientId: client_id
    payeeAccountId: payee_account_id
    timezone
    status
    voidedReason: voided_reason
    voidedAt: voided_at
    noticeIndex: notice_index
    violationType: violation_type
    description
    baseAmountDue: base_amount_due
    additionalFeeAmountDue: additional_fee_amount_due
    escalationFeeAmountDue: escalation_fee_amount_due
    feeAmountWaived: fee_amount_waived
    roLookupStatus: ro_lookup_status
    roLookupSubmittedAt: ro_lookup_submitted_at
    roLookupCompletedAt: ro_lookup_completed_at
    initialLetterRequestedAt: initial_letter_requested_at
    initialLetterSentAt: initial_letter_sent_at
    isWarning: is_warning
    dueAt: due_at
    vehicleMake: vehicle_make
    vehicleModel: vehicle_model
    vehicleColor: vehicle_color
    officerBadgeId: officer_badge_id
    officerName: officer_name
    reviews {
      id
      type
      status
      deniedReason: denied_reason
      requestedAt: requested_at
      reviewedAt: reviewed_at
    }
    sessions(order_by: [{ started_at: asc }]) {
      id
      parkId: park_id
      doubleParkId: double_park_id
      type
      transactionId: transaction_id
      transaction {
        ...transactionDetailFragment
      }
      licensePlateImageUrl: license_plate_image_url
      startImageUrl: start_image_url
      endImageUrl: end_image_url
      startedAt: started_at
      endedAt: ended_at
      streetAddress: street_address
      violationImageUrl: violation_image_url
      violationAt: violation_at
    }
    payments(order_by: [{ paid_at: asc }]) {
      id
      paidAmount: paid_amount
      paidAt: paid_at
    }
  }
  ${enforcementIntentSummaryFragment}
  ${transactionDetailFragment}
`;

export interface EnforcementIntentDetail extends EnforcementIntentSummary {
  clientId: string;
  payeeAccountId: string;
  timezone: string;
  status: string; // TODO: enum
  voidedReason: string | null;
  voidedAt: string | null;
  noticeIndex: number;
  description: string;
  baseAmountDue: number;
  additionalFeeAmountDue: number;
  escalationFeeAmountDue: number;
  feeAmountWaived: number;
  roLookupStatus: string; // TODO: enum
  roLookupSubmittedAt: string | null;
  roLookupCompletedAt: string | null;
  initialLetterRequestedAt: string | null;
  initialLetterSentAt: string | null;
  isWarning: boolean;
  dueAt: string | null;
  vehicleMake: string;
  vehicleModel: string;
  vehicleColor: string;
  officerBadgeId: string | null;
  officerName: string | null;
  reviews: {
    id: string;
    type: string; // TODO: enum
    status: string; // TODO: enum
    deniedReason: string | null;
    requestedAt: string;
    reviewedAt: string | null;
  }[];
  sessions: {
    id: string;
    parkId: string;
    doubleParkId: string;
    type: string; // TODO: enum
    transactionId: string;
    transaction: TransactionDetail;
    licensePlateImageUrl: string;
    startImageUrl: string;
    endImageUrl: string;
    startedAt: string;
    endedAt: string;
    streetAddress: string;
    violationImageUrl: string | null;
    violationAt: string | null;
  }[];
  payments: {
    id: string;
    paidAmount: number;
    paidAt: string;
  }[];
}
