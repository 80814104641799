import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme, Theme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PartnerLogoImage from "../PartnerLogoImage";
import { CityOption } from "common/constants";

export const MobileHeader: React.FC<MobileHeaderProps> = ({ cityOption }) => {
  const theme = useTheme();

  const headerText = `In partnership with ${cityOption.landingPageLabel}`;

  return (
    <Stack
      sx={{
        color: theme.palette.primary.white,
        backgroundColor: "#E8E8E8",
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        right: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      direction="row"
      spacing={1}
    >
      <PartnerLogoImage cityOption={cityOption} />
      <Typography variant="caption" color={(theme: Theme) => theme.palette.text.primary}>
        {headerText}
      </Typography>
    </Stack>
  );
};

export interface MobileHeaderProps {
  cityOption: CityOption;
}

export default MobileHeader;
