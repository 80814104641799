import { gql } from "@apollo/client";
import { enforcementIntentDetailFragment, EnforcementIntentDetail } from "../fragments/enforcementIntentDetailFragment";

export const GET_ENFORCEMENT_INTENT_DETAIL = gql`
  query GetEnforcementIntent($id: uuid!) {
    enforcementIntent: enforcement_intent_by_pk(id: $id) {
      ...enforcementIntentDetailFragment
    }
  }
  ${enforcementIntentDetailFragment}
`;

export type GetEnforcementIntentDetailVars = {
  id: string;
};

export interface GetEnforcementIntentDetailData {
  enforcementIntent: EnforcementIntentDetail;
}
