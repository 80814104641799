import React from "react";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { Theme } from "@mui/material/styles";

export const CurbPassSettingsUnsavedIndicator: React.FC<CurbPassSettingsUnsavedIndicatorProps> = ({ BoxProps }) => {
  return (
    <Box
      {...BoxProps}
      sx={{
        ...BoxProps?.sx,
        display: "inline-block",
        backgroundColor: (theme: Theme) => theme.palette.grey["200"],
        px: 1,
        py: 0.5,
      }}
    >
      <Typography color={"text.secondary"}>Unsaved</Typography>
    </Box>
  );
};

export interface CurbPassSettingsUnsavedIndicatorProps {
  BoxProps?: BoxProps;
}

export default CurbPassSettingsUnsavedIndicator;
