import { gql } from "@apollo/client";
import { Wallet, walletFragment } from "./walletFragment";

export const accountFragment = gql`
  fragment accountFragment on account {
    holderName: holder_name
    id
    organizationName: organization_name
    ownerType: owner_type
    roleType: role_type
    parentAccountId: parent_account_id
    wallets(order_by: { created_at: desc }) {
      ...walletFragment
    }
    isFreeTrial: is_free_trial
  }
  ${walletFragment}
`;

export interface Account {
  holderName: string;
  id: string;
  organizationName: string;
  ownerType: AccountOwnerType;
  roleType: AccountRoleType;
  parentAccountId: string | null;
  wallets: Wallet[];
  isFreeTrial: boolean;
}

export type AccountOwnerType = "fleet_operator" | "individual_driver" | "city" | "airport" | "internal_user";

export type AccountRoleType = "payer" | "payee";

export default accountFragment;
