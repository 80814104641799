import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { formatCurrency } from "common/format";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

/**
 * Component that allows a user to select a predefined amount
 * @param value Value of the button, in the smallest denomination of the currency
 * @param isSelected Selection status
 * @param onClick Event handler fired on click
 * @param loading Indicates whether component is loading. Defaults to false.
 * @constructor
 */
export const AmountSelectButton: React.FC<AmountSelectButtonProps> = ({ value, isSelected, onClick, ...rest }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Button
      sx={{ backgroundColor: isSelected ? (theme: Theme) => theme.palette.action.hover : undefined }}
      variant={"outlined"}
      size={isXs ? "medium" : "large"}
      color={"primary"}
      onClick={onClick}
      value={value}
      {...rest}
    >{`$${formatCurrency(value)}`}</Button>
  );
};

export interface AmountSelectButtonProps extends Omit<ButtonProps, "variant" | "size" | "color" | "value"> {
  /** Selection status of the button */
  isSelected: boolean;
  /** Value of the button */
  value: number;
  /** Click handler */
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default AmountSelectButton;
