import { gql } from "@apollo/client";
import { enforcementIntentSummaryFragment, EnforcementIntentSummary } from "../fragments/enforcementIntentSummary";

export const GET_ISSUED_ENFORCEMENT_INTENTS = gql`
  query GetIssuedEnforcementIntents($offset: Int = 0) {
    enforcementIntents: enforcement_intent(
      where: { ro_lookup_submitted_at: { _is_null: false } }
      offset: $offset
      limit: 100 #TODO: add order by
    ) {
      ...enforcementIntentSummaryFragment
    }
  }
  ${enforcementIntentSummaryFragment}
`;

export interface GetIssuedEnforcementIntentsVars {
  offset: number;
}

export interface GetIssuedEnforcementIntentsData {
  enforcementIntents: EnforcementIntentSummary[];
}

export const GET_ISSUED_ENFORCEMENT_INTENTS_OVERVIEW = gql`
  query GetIssuedEnforcementIntentsOverview {
    enforcementIntentPaymentAggregate: enforcement_intent_payment_aggregate {
      aggregate {
        sum {
          paidAmount: paid_amount
        }
      }
    }
  }
`;

export interface GetIssuedEnforcementIntentsOverviewData {
  enforcementIntentPaymentAggregate: {
    aggregate: {
      sum: {
        paidAmount: number;
      };
    };
  };
}
