import { useEffect, useState, useCallback } from "react";
import { CityLocationInfo } from "@app.automotus.io/components/scenes/SignUp/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import { findNearestLocation } from "common/helpers";
import { CITY_OPTIONS, CityOption } from "common/constants";
import { useGeoLocation } from "@app.automotus.io/components/hooks/useGeoLocation";
import { activePayeeAccountIdVar } from "common/graphql/cache";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export function useActivePayee(): UseActivePayeeAccountIdResult {
  const activePayeeId = useReactiveVar(activePayeeAccountIdVar);
  const cityOption = CITY_OPTIONS.find(({ payeeAccountId }) => activePayeeId && payeeAccountId === activePayeeId);
  const [payeeInfo, setPayeeInfo] = useState(
    cityOption ? { payeeAccountId: cityOption.payeeAccountId, cityName: cityOption.value } : null,
  );
  const { loading, error: locationError, location } = useGeoLocation();
  const [error, setError] = useState<Error>();
  const { userProfile } = useUserProfile();

  const setActivePayee = useCallback((newActivePayee: CityLocationInfo) => {
    setError(undefined);
    localStorage.setItem("activePayeeAccountId", newActivePayee.payeeAccountId);
    activePayeeAccountIdVar(newActivePayee.payeeAccountId);
    setPayeeInfo(newActivePayee);
  }, []);

  useEffect(() => {
    if (!payeeInfo) {
      // If no payee info is set, we'll try to decide one automatically.
      if (userProfile?.account?.wallets?.length) {
        // If the user has wallets assigned to his account, we'll examine the first
        // wallet owned by the account.
        const mostRecentlyCreatedWallet = userProfile.account.wallets[0];

        let newActivePayee: CityOption | undefined;
        if (mostRecentlyCreatedWallet.applicableGateways.length === 1) {
          // If this wallet is assigned to a specific city, then we'll set the active
          // payee to that city.
          newActivePayee = CITY_OPTIONS.find(
            ({ payeeAccountId }) => payeeAccountId === mostRecentlyCreatedWallet.applicableGateways[0].payeeAccountId,
          );
        } else {
          if (location) {
            // If the wallet is not assigned to a specific city then we'll use the
            // user's current location to determine the nearest city with which the
            // wallet can be used.
            const nearestCity = findNearestLocation(
              CITY_OPTIONS.filter((opt) =>
                mostRecentlyCreatedWallet.applicableGateways.some(
                  ({ payeeAccountId }) => payeeAccountId === opt.payeeAccountId,
                ),
              ),
              location,
            );

            if (nearestCity) {
              newActivePayee = nearestCity;
            } else {
              setError(new Error("no nearest payee exists"));
              return;
            }
          } else {
            // If no location is available, we'll just use the first wallet
            newActivePayee = CITY_OPTIONS.find(
              ({ payeeAccountId }) => payeeAccountId === mostRecentlyCreatedWallet.applicableGateways[0].payeeAccountId,
            );
          }
        }

        if (newActivePayee) {
          setActivePayee({
            payeeAccountId: newActivePayee.payeeAccountId,
            cityName: newActivePayee.value,
          });
          return;
        }
      }

      if (location) {
        // If the user does not have any wallets assigned to his account, then we'll
        // simply use the user's current location to determine the nearest city.
        const nearestCity = findNearestLocation(CITY_OPTIONS, location);
        if (!nearestCity) {
          setError(new Error("no nearest payee exists"));
          return;
        }
        setPayeeInfo({
          payeeAccountId: nearestCity.payeeAccountId,
          cityName: nearestCity.value,
        });
      }
    }
  }, [payeeInfo, location, setActivePayee, userProfile]);

  return {
    loading,
    error: error || locationError,
    activePayee: payeeInfo,
    payeeSelectedByUser: !!activePayeeId,
    setActivePayee,
  };
}

export interface UseActivePayeeAccountIdResult {
  loading: boolean;
  error?: Error;
  activePayee: CityLocationInfo | null;
  payeeSelectedByUser: boolean;
  setActivePayee: (newActivePayee: CityLocationInfo) => void;
}

export default useActivePayee;
