import { gql } from "@apollo/client";
import { EnforcementIntentDetail, enforcementIntentDetailFragment } from "../fragments";

export const REVIEW_ENFORCEMENT_INTENT = gql`
  mutation ReviewEnforcementIntent($intentId: uuid!, $approve: Boolean!, $deniedReason: String) {
    review_enforcement_intent(enforcement_intent_id: $intentId, approve: $approve, denied_reason: $deniedReason) {
      enforcement_intent {
        ...enforcementIntentDetailFragment
      }
    }
  }
  ${enforcementIntentDetailFragment}
`;

export interface ReviewEnforcementIntentData {
  review_enforcement_intent: {
    enforcement_intent: EnforcementIntentDetail;
  };
}

export type ReviewEnforcementIntentVars = ApproveEnforcementIntentVars | DenyEnforcementIntentVars;

export interface ApproveEnforcementIntentVars {
  intentId: string;
  approve: true;
  deniedReason?: never;
}

export interface DenyEnforcementIntentVars {
  intentId: string;
  approve: false;
  deniedReason: string;
}

export default REVIEW_ENFORCEMENT_INTENT;
