import React from "react";
import { DesktopNavBarItem } from "@app.automotus.io/components/NavBar/NavBarItem/DesktopNavBarItem";
import { NavBarTarget } from "@app.automotus.io/components/NavBar";
import Box from "@mui/material/Box";
import { GetAllInvoicesData, GetPendingEnforcementIntentsData } from "common/graphql";
import Stack from "@mui/material/Stack";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useLogout } from "@app.automotus.io/components/auth";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: "84px",
    backgroundColor: theme.palette.background.paper,
    marginTop: `${theme.spacing(0.75)} !important`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[3],
  },
}));

export const DesktopNavBarMenu: React.FC<DesktopNavBarMenuProps> = ({
  invoicesData,
  invoicesLoading,
  pendingEnforcementIntentsData,
  pendingEnforcementIntentsLoading,
  targets,
}) => {
  const { userProfile } = useUserProfile();
  const logout = useLogout();
  const navigate = useNavigate();

  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"}>
      <Stack direction={"row"} spacing={2.5} alignItems="center">
        {targets
          .filter((target) => target.title !== "Logout")
          .map((target) => (
            <DesktopNavBarItem
              key={target.to}
              invoicesData={invoicesData}
              invoicesLoading={invoicesLoading}
              pendingEnforcementIntentsData={pendingEnforcementIntentsData}
              pendingEnforcementIntentsLoading={pendingEnforcementIntentsLoading}
              {...target}
            />
          ))}
        <CustomTooltip
          placement="bottom-end"
          title={
            <Stack spacing={1}>
              <Button
                component={"a" as const}
                target="_blank"
                href={process.env.REACT_APP_CURBSUITE_ZENDESK_SUPPORT_TICKET_URL || ""}
                sx={{
                  textTransform: "none",
                }}
              >
                Support
              </Button>
              <Button
                variant="text"
                sx={{
                  color: (theme: Theme) => theme.palette.error.main,
                  textTransform: "none",
                  py: (theme: Theme) => theme.spacing(0.75),
                  px: (theme: Theme) => theme.spacing(1),
                  my: "auto",
                  fontSize: "1rem",
                  fontWeight: 400,
                }}
                fullWidth
                onClick={async (e: React.SyntheticEvent) => {
                  e.preventDefault();
                  await logout();
                }}
              >
                Logout
              </Button>
            </Stack>
          }
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: (theme: Theme) => theme.spacing(0.5),
              border: "1px solid",
              borderColor: "primary.main",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "primary.main",
              fontSize: "20px",
              fontWeight: 400,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/account");
            }}
          >
            {userProfile?.givenName?.charAt(0).toUpperCase()}
            {userProfile?.familyName?.charAt(0).toUpperCase()}
          </Box>
        </CustomTooltip>
      </Stack>
    </Box>
  );
};

export interface DesktopNavBarMenuProps {
  invoicesData?: GetAllInvoicesData;
  invoicesLoading?: boolean;
  pendingEnforcementIntentsData?: GetPendingEnforcementIntentsData;
  pendingEnforcementIntentsLoading?: boolean;
  targets: NavBarTarget[];
}
